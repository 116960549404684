import React from 'react'
import Title from "../../components/title/Title"
import { Link } from "react-router-dom";

const AscendersBlog2: React.FC = () => {
  return (
    <div className="row">
      <Title name="Behind the Development: Ascenders (2 / 2)"></Title>
      <div className="col-12">
        <span className="badge bg-secondary mb-2">28 March 2020</span>

        <p>
          This is the second and final part of Behind the Design: Ascenders. This post is about the actual level of the game, where the two players fight to first reach the beacon!
          If you haven't checked out the first part about the tutorial you can do it here: <Link to="/blog/ascenders-1">Behind the Development: Ascenders (1 / 2)</Link>
        </p>

        {/* Video */}
        <div className="col-12">
          <h5>Video commentary</h5>
          <iframe width="100%" height="630px" src="https://www.youtube.com/embed/dBdJPHKn-xk?si=yKbRWC4Cne_mGpf3" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>

        {/* Summary */}
        <div className="col-12">
          <h5>Summary</h5>

          <p>
            Thank you for watching the video and I hope you enjoy the format.
            I try to scale down the text and content in these blog posts as much as I can, cause we only have that much attention span am I right?
          </p>

          <h5>- Joakim Malmström</h5>
        </div>
      </div>
    </div>
  )
}

export default AscendersBlog2