import React from 'react';
import './App.css';
import Header from './components/header/Header';
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import HomewardBound from "./pages/games/homeward-bound/HomewardBound";
import Home from "./pages/home/Home";
import Ascenders from "./pages/games/ascenders/Ascenders";
import Behemoth from "./pages/games/behemoth/Behemoth";
import PaintYourWay from "./pages/games/paint-your-way/PaintYourWay";
import Regrowth from "./pages/games/regrowth/Regrowth";
import Blog from "./pages/blog/Blog";
import BehemothBlog from "./pages/blogs/BehemothBlog";
import PaintYourWayBlog from "./pages/blogs/PaintYourWayBlog";
import AscendersBlog1 from "./pages/blogs/AscendersBlog1";
import AscendersBlog2 from "./pages/blogs/AscendersBlog2";
import HomewardBoundBlog from "./pages/blogs/HomewardBoundBlog";
import LmsBlog from "./pages/blogs/LmsBlog";
import WolfPackBlog1 from "./pages/blogs/WolfPackBlog1";

const Layout = () => (
  <>
    <Header />
    <div className="container">
      <Outlet />
    </div>
  </>
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/homeward-bound",
        element: <HomewardBound />,
      },
      {
        path: "/ascenders",
        element: <Ascenders />,
      },
      {
        path: "/behemoth",
        element: <Behemoth />,
      },
      {
        path: "/paint-your-way",
        element: <PaintYourWay />,
      },
      {
        path: "/regrowth",
        element: <Regrowth />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/behemoth",
        element: <BehemothBlog />
      },
      {
        path: "/blog/paint-your-way",
        element: <PaintYourWayBlog />
      },
      {
        path: "/blog/ascenders-1",
        element: <AscendersBlog1 />
      },
      {
        path: "/blog/ascenders-2",
        element: <AscendersBlog2 />
      },
      {
        path: "/blog/homeward-bound",
        element: <HomewardBoundBlog />
      },
      {
        path: "/blog/lms",
        element: <LmsBlog />
      },
      {
        path: "/blog/wolf-pack-1",
        element: <WolfPackBlog1 />
      }
    ]
  },
]);

const App: React.FC = () => {
  return (
    <div className="app">
      <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
    </div>
  );
}

export default App;
