import React from 'react'
import Title from "../../components/title/Title"
import ImageService from "../../services/ImageService.service"

const WolfPackBlog1: React.FC = () => {
  return (
    <div className="row">
      <Title name="Project Wolf Pack - Part 1"></Title>

      <div className="col-12">
        <span className="badge bg-secondary mb-2">12 April 2021</span>
        <p>
          You're a pack of wolves running through the woods and do what needs to be done in order to survive.
          You're on the hunt for wildlife and on the lookout for hunters. During two weeks I made a one-page-design, a backlog and some implemented features.
          This is a hobby project of mine that I did to improve my C++ and game programming.
        </p>

        {/* Video documentation */}
        <div className="col-12 mb-4">
          <h5>Video documentation</h5>
          <iframe width="100%" height="630px" src="https://www.youtube.com/embed/fKwxB_YKEdg?si=K4wCX6VGJzO7LLdf" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>

        {/* One page design */}
        <div className="col-12 mb-4">
          <h5>One page design</h5>
          <img src={ImageService.getImage('wolf-pack/wolf-one-page')} alt="" className="img img-fluid" />
        </div>
        <div className="col-12">
          <h5>- Joakim Malmström</h5>
        </div>
      </div>
    </div>
  )
}

export default WolfPackBlog1