import React from 'react';
import './Portfolio.css';
import Title from "../title/Title";
import { Link } from "react-router-dom";
import ImageService from "../../services/ImageService.service";

interface PortfolioGameProps {
  name: string;
  src: string;
  path: string;
}

const Portfolio: React.FC = () => {
  const games: PortfolioGameProps[] = [
    {
      name: 'homeward-bound',
      src: ImageService.getPortfolioImage('homeward'),
      path: '/homeward-bound',
    },
    {
      name: 'ascenders',
      src: ImageService.getPortfolioImage('ascenders'),
      path: '/ascenders',
    },
    {
      name: 'behemoth',
      src: ImageService.getPortfolioImage('behemoth'),
      path: '/behemoth',
    },
    {
      name: 'paint-your-way',
      src: ImageService.getPortfolioImage('paint-your-way'),
      path: '/paint-your-way',
    },
    {
      name: 'regrowth',
      src: ImageService.getPortfolioImage('regrowth'),
      path: '/regrowth',
    }
  ];

  return (
    <div className="col mt-2">
      <Title name="Portfolio"></Title>

      {/* Games */}
      <div className="row">
        {games.map((game, index) => (
          <div key={index} className={`col-12 col-lg-${index < 2 ? 6 : 4} mb-3`}>
            <Link to={game.path}>
              <img src={game.src} alt={game.name} className="image img-fluid" />
            </Link>
          </div>
        ))}
      </div>
    </div >
  )
}

export default Portfolio