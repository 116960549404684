import React from 'react'
import Title from "../../components/title/Title"
import './PaintYourWayBlog.css'
import ImageService from "../../services/ImageService.service";

const PaintYourWayBlog: React.FC = () => {
  const tutorialPrototypePoints = [
    'The C represents the checkpoints the player needed to pick up for completing their track',
    'The coloring jars were supposed to give the player extra points but that mechanic was later scrapped during development',
    'This tutorial level would let the player, in a safe and easy environment learn the controls. One obstacle is in the way so that the player must make a turn around it',
    'The 2 checkpoints are in front of each other for maximum visibility. So that the player would not miss the second one'
  ];
  const tutorialGamePoints = [
    'At the top we have two animated rollers that gives instructions on how the controllers work',
    'Painting Phase: Roll up to paint track and get the checkpoints. Roll back to erase track',
    'Racing Phase: Roll up to increase speed and roll back to slow down',
    'Changes from the prototype: The finish lines for each player are straight up instead of turning to the side'
  ];
  const level1PrototypePoints = [
    `I don't want to overwhelm them with their first 'real' level`,
    `When they race, they are able to collide with each other. That's why I wanted it in the middle of the level, where they all meet for their second checkpoint. To get that first laughter of the game and enhance the easy-going/party-game feeling to it`,
  ];
  const level1GamePoints = [
    'I made some more space in the level. It felt too cramped and frustrating to move around in-game',
    `I designed an option of going around the three stones or in between it. Despite it's symmetrical layout, I wanted to give some alternative paths`
  ];
  const level2PrototypePoints = [
    `I wanted to make an interesting level with a motoric challenge and that's why this slalom route was made`,
    `This was risky because I didn't know if the players would appreciate the slalom pattern. It could create a lot of frustration`,
  ];
  const level2GamePoints = [
    `I iterated this level the most, I made space for the slalom and adjust the numbers to it's end to get all tracks symmetrical`,
    `Instead of having obstacles at the sides I added some in the middle instead to get a better flow`,
    `In hindsight I would place this level last due to it's difficulty`
  ];
  const level3PrototypePoints = [
    `I wanted to make an interesting level with a motoric challenge and that's why this slalom route was made`,
    `I wanted that 'shouting at the screen'-moment when someone is close to winning but it can all be taken away with a crash`,
  ];
  const level3GamePoints = [
    'The first iteration? You guessed it, more SPACE',
    'The panda did not make the game but we got the koala',
    'Some evil-minded players wanted to go through the fence and over the koala. Spoiler: that was not possible'
  ];

  return (
    <div className="row">
      <Title name="Behind the Development: Paint Your Way"></Title>

      <div className="col-12">
        <span className="badge bg-secondary mb-2">14 March 2020</span>

        <p>
          Paint Your Way is a four player, competitive, 2D isometric, goofy, racing game with a special designed paint roller as the controller. Each player paints their own track from start to finish. When every player is done or when the time runs out the phase changes from painting to racing.
        </p>

        {/* TUTORIAL */}
        <h5>Tutorial prototype</h5>
        <p>
          This is what I was working on while the programmers setting up the initial code for the game. I used Adobe Photoshop (actually, my first time), to prototype the levels so I had something to relay on when it was time to do it in the engine.
        </p>
        <div className="row">
          <div className="col-6">
            <h5>Prototype</h5>
            <img src={ImageService.getImage('paint-your-way/painted-prototype-tutorial')} alt="" className="img img-fluid" />
            <ul>
              {tutorialPrototypePoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
          <div className="col-6">
            <h5>In Game</h5>
            <img src={ImageService.getImage('paint-your-way/game-tutorial')} alt="" className="img img-fluid" />
            <ul>
              {tutorialGamePoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* LEVEL 1 */}
        <h5>Level 1</h5>
        <p>
          Now it was time to let the players engage themselves in a slightly more complex level than the tutorial.
        </p>
        <div className="row">
          <div className="col-6">
            <h5>Prototype</h5>
            <img src={ImageService.getImage('paint-your-way/painted-prototype-level-1')} alt="" className="img img-fluid" />
            <ul>
              {level1PrototypePoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
          <div className="col-6">
            <h5>In Game</h5>
            <img src={ImageService.getImage('paint-your-way/game-level-1')} alt="" className="img img-fluid" />
            <ul>
              {level1GamePoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* LEVEL 2 */}
        <h5>Level 2</h5>
        <p>
          Now it was time to let the players engage themselves in a slightly more complex level than the tutorial.
        </p>
        <div className="row">
          <div className="col-6">
            <h5>Prototype</h5>
            <img src={ImageService.getImage('paint-your-way/painted-prototype-level-2')} alt="" className="img img-fluid" />
            <ul>
              {level2PrototypePoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
          <div className="col-6">
            <h5>In Game</h5>
            <img src={ImageService.getImage('paint-your-way/game-level-2')} alt="" className="img img-fluid" />
            <ul>
              {level2GamePoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* LEVEL 3 */}
        <h5>Level 3</h5>
        <div className="row">
          <div className="col-6">
            <h5>Prototype</h5>
            <img src={ImageService.getImage('paint-your-way/painted-prototype-level-3')} alt="" className="img img-fluid" />
            <ul>
              {level3PrototypePoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
          <div className="col-6">
            <h5>In Game</h5>
            <img src={ImageService.getImage('paint-your-way/game-level-3')} alt="" className="img img-fluid" />
            <ul>
              {level3GamePoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Summary */}
        <h5>Summary</h5>
        <p>The prototyping worked in my favor. I could design and 'test' the levels with the pen tool in photoshop to see how players could interact with the game. This was also the first time that I worked hand-on in an engine. It was really a wow-moment when the level came alive with all its assets. I learned how powerful level design could be, how it alters the game and how it the players experience it.</p>

        <h5>- Joakim Malmström</h5>
      </div>
    </div>
  )
}

export default PaintYourWayBlog