import React from 'react'
import Title from "../../components/title/Title"

const BehemothBlog: React.FC = () => {
  const startPoints = [
    'The screen states: "HOLD SPACE"',
    'If the player do not follow the command and just tapping a normal shot would fire',
    'If the player executes it properly he/she learns how to use the heavy shot',
    `Player enters the menu and navigates with 'W' and 'S' for up and down`,
    'Player press down space to choose an option'
  ];
  const levelPoints = [
    'Starts slow with kamikaze ships that are weak and it is primary objective is to crash into the player. We want the player to have a fairly safe learning environment from the start. To get use to the controls and get a feel for the game',
    'Now it spawns more kamikaze ships and we introduce another enemy, shooters, that obviously shoots projectiles',
    'The spawner, another new enemy that spawns kamikaze ships. The Spawner has more hit points and serve an acute threat',
    `This is one of the peak points in the game. It's actually calculated that the player would get a "perfectly timed" power-up to overcome this danger`,
    `This part the player can breathe out, no enemies spawning and they can repair themselves. Also the background changes to let the player know that they're getting closer to their objective`,
    `My favourite part, the boss level. Since we didn't have the time to make a new enemy I came up with the idea that we use what we have; the protective shields. The cannon stops working and the kamikaze ships are sped to increase difficulty`,
    'After intense boss sequence the player completes the level'
  ];

  return (
    <div className="row">
      <Title name="Behind the Development: Behemoth"></Title>
      <div className="col-12">
        <span className="badge bg-secondary mb-2">8 March 2020</span>
        <p>
          Behemoth is a 2D side-scrolling Shoot 'em Up where you control this big ship with firing cannons and protective shields to destroy enemy ships.
        </p>
        <p>
          With this blog post I'll go through the decisions and thoughts behind some major design points in this game. Starting of where it all starts; the start screen.
        </p>

        <h5>Start screen</h5>
        <div className="col-12">
          <iframe width="100%" height="630px" src="https://www.youtube.com/embed/Dwcf4JMlEjU?si=zxy4HHMiBdHi77AF" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>

        <p>
          This is the first time that I used this neat little trick, learning the player the core mechanics from an interactive start menu.
        </p>
        <ul>
          {startPoints.map(point => (
            <li>{point}</li>
          ))}
        </ul>
        <p>
          With these steps we have shown the player the core mechanics of the game. How to shoot and how to maneauver the cannon.
        </p>

        <h5>Level design</h5>
        <p>
          I'll go through the level design in seven steps. The video shows you the critical points from gameplay.
        </p>

        <div className="col-12">
          <iframe width="100%" height="630px" src="https://www.youtube.com/embed/8gwI-deBvzM?si=sIXHKsmueL6Fhm56" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>

        <ol>
          {levelPoints.map(point => (
            <li>{point}</li>
          ))}
        </ol>
        <p>
          Hope you liked this segment 'behind the design' because there's more coming! If you have any feedback to provide or anything really give me a comment or mail me.
        </p>

        <h5>- Joakim Malmström</h5>
      </div>
    </div>
  )
}

export default BehemothBlog