import React from 'react'
import './Title.css'

interface TitleProps {
  name: string;
  isGameTitle?: boolean;
  date?: string,
}

const Title: React.FC<TitleProps> = ({ name, isGameTitle }) => {
  return (
    <div className={isGameTitle ? 'game-title' : 'title' + ' col-12'}>
      <h1>{name}</h1>
    </div>
  )
}

export default Title