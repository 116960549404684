import React from 'react'
import Title from "../../../components/title/Title";
import ImageService from "../../../services/ImageService.service";

const Regrowth: React.FC = ({ }) => {
  return (
    <div>
      <Title name="Regrowth" isGameTitle={true}></Title>

      {/* About */}
      <div className="row center-content">
        <div className="col-8">
          <p>
            Regrowth is a mobile app designed to make rehabilitation fun. The player completes mini-games to earn flowers and plants to grow in a garden.
          </p>
          <p>
            The mini-games are designed for patients with brain damage, and are aimed at improving and training memory, reaction and cognitive abilities.
            Therefore, to get the full experience out of Regrowth, the developers recommend the game to be used in discussion with doctors and psychiatrists.
          </p>
        </div>
      </div>

      {/* Game Images */}
      <div className="row mb-3 center-content">
        <div className="col-12 col-lg-3 mb-3">
          <img src={ImageService.getImage('regrowth/regrowth-1')} className="image img-fluid" alt="regrowth-game-img-1" />
        </div>
        <div className="col-12 col-lg-3 mb-3">
          <img src={ImageService.getImage('regrowth/regrowth-2')} className="image img-fluid" alt="regrowth-game-img-2" />
        </div>
        <div className="col-12 col-lg-3 mb-3">
          <img src={ImageService.getImage('regrowth/regrowth-3')} className="image img-fluid" alt="regrowth-game-img-3" />
        </div>
      </div>

      {/* Developers / Blog */}
      <div className="row center-content">
        <div className="col-12 col-lg-5">
          <h5>Developers</h5>
          <p>Joakim Malmström - QA & Scrum Master</p>
          <p>Eira Saastamoinen - Game Designer & Product Owner</p>
          <p>Clément Pirelli - Game Programmer</p>
          <p>Karl Lindkvist - Game Designer</p>
          <p>Matthias Jannidis - Game Designer</p>
          <p>Lilja Bräckman - Graphical Artist</p>
          <p>Carl Hvarfvenius Blomgren - Graphical Artist</p>
        </div>
      </div>
    </div>
  );
};

export default Regrowth;