import React from 'react'
import Title from "../../components/title/Title"
import "./HomewardBoundBlog.css";
import ImageService from "../../services/ImageService.service"

const HomewardBoundBlog: React.FC = () => {
  return (
    <div className="row">
      <Title name="Homeward Bound released!"></Title>

      <div className="col-12">
        <span className="badge bg-secondary mb-2">22 June 2020</span>
        <h5>Game cover</h5>
        <img src={ImageService.getImage('homeward-bound/ba-game')} alt="" className="img img-fluid" />
      </div>

      <div className="row text-align-center">
        <div className="col-6">
          <p>Before</p>
        </div>
        <div className="col-6">
          <p>After</p>
        </div>
      </div>

      <h5>Flinging objects</h5>
      <div className="row text-align-center">
        <div className="col-12 col-lg-6">
          <img src={ImageService.getImage('homeward-bound/before-rope', true)} alt="" className="img img-fluid" />
          <p>Before</p>
        </div>
        <div className="col-12 col-lg-6">
          <img src={ImageService.getImage('homeward-bound/after-rope', true)} alt="" className="img img-fluid" />
          <p>After</p>
        </div>
      </div>

      <h5>CO-OP problem solving</h5>
      <div className="row text-align-center">
        <div className="col-12 col-lg-6">
          <img src={ImageService.getImage('homeward-bound/before-lever', true)} alt="" className="img img-fluid" />
          <p>Before</p>
        </div>
        <div className="col-12 col-lg-6">
          <img src={ImageService.getImage('homeward-bound/after-swinging', true)} alt="" className="img img-fluid" />
          <p>After</p>
        </div>
      </div>

      <div className="col-12">
        <h5>Summary</h5>

        <p>
          Me and a small team, developed this outside of our studies over a period of two months. I would like to really thank me incredible team for making this small dream of mine come true!
        </p>

        <h5>- Joakim Malmström</h5>
      </div>
    </div>
  )
}

export default HomewardBoundBlog