import React from 'react'
import Title from "../../../components/title/Title"
import { Link } from "react-router-dom";
import ImageService from "../../../services/ImageService.service";

const PaintYourWay: React.FC = ({ }) => {
  return (
    <div>
      <Title name="Paint Your Way" isGameTitle={true}></Title>

      {/* About */}
      <div className="row center-content">
        <div className="col-8">
          <p>
            Paint Your Way is a four player, competitive, 2D isometric, goofy, racing game with a special designed paint roller as a controller!
          </p>
          <p>
            Each player paints their own track from start to finish. When every player is done or when the time runs out the phase changes from painting to racing.
          </p>
          <p>
            Accelerate by rolling forward to boost your race car's speed. But be cautious—going too fast can cause you to lose traction. Roll backward to brake and regain control!
          </p>
        </div>
      </div>

      {/* Trailer */}
      <div className="row">
        <div className="col-12">
          <iframe width="100%" height="630px" src="https://www.youtube.com/embed/_veL1BJ_hMs?si=GhqfQcKNN-mG5oA7" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
      </div>

      {/* Developers / Blog */}
      <div className="row center-content">
        <div className="col-12 col-lg-5">
          <h5>Developers</h5>
          <p>Joakim Malmström - Game Designer & Product Owner</p>
          <p>Samuel Karabetian - Game Programmer</p>
          <p>Kentaro Hayashida - Game Programmer</p>
          <p>Niklas Ericsson - Scrum Master</p>
          <p>Clara Cox - Graphical Artist</p>
          <p>Marie Collin - Graphical Artist</p>
        </div>
        <div className="col-12 col-lg-3">
          <h5>Behind the Development</h5>
          <Link to="/blog/paint-your-way">
            <img src={ImageService.getPortfolioImage('paint-your-way')} className="image img-fluid" alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaintYourWay;