import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="header-text">
          <h1>Joakim Malmström</h1>
          <span>Game & Software Developer</span>
        </div>
        <nav>
          <ul className="header-nav">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header >
  );
};

export default Header;
