type GameName =
  'homeward' |
  'ascenders' |
  'behemoth' |
  'paint-your-way' |
  'regrowth';

const ImageService = {
  getImage(path: string, gifExtension?: boolean): string {
    const extension = gifExtension ? '.gif' : '.png';
    return `${process.env.PUBLIC_URL}/images/${path}${extension}`;
  },

  getPortfolioImage(name: GameName): string {
    return `${process.env.PUBLIC_URL}/images/portfolio/${name}.png`;
  },

  getItchIoImage(): string {
    return `${process.env.PUBLIC_URL}/images/itch-io.svg`;
  },
};

export default ImageService;