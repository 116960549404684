import React from 'react'
import Title from "../title/Title"
import ImageService from "../../services/ImageService.service"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const About: React.FC = () => {
  const gameEngines = [
    'UE4', 'UE5', 'Unity'
  ];
  const languages = [
    'C++', 'C#', 'JavaScript', 'Html', 'CSS', 'Typescript', 'YAML', 'SQL'
  ];
  const frameworks = [
    'Angular', 'React', 'WPF', 'Razor'
  ];
  const cloud = [
    'AWS', 'Microsoft Azure'
  ];

  return (
    <div className="col mt-2">
      <Title name="About"></Title>

      <div className="row">
        {/* Image */}
        <div className="col-6 col-lg-2 mb-4">
          <img src={ImageService.getImage('profile')} alt="profile-image" className="image img-fluid" />
        </div>
        {/* Bio */}
        <div className="col-12 col-lg-6">
          <h5>Bio</h5>
          <p>
            I'm an involuntarily morning person that's always on time and more often than not drinking coffee. A creative at heart but cannot live without routines and structure.
          </p>
          <p>
            When I'm not coding or gaming, I spend my time practicing MMA, lifting weights, swimming (with mandatory sauna), painting Warhammer figurines, learning Iron Maiden's Phantom of the Opera on electric guitar or hanging out with friends.
          </p>
          <div className="row mb-4">
            {/* Contact */}
            <h5>Contact</h5>
            <div className="col-3 col-lg-1">
              <a href="https://www.linkedin.com/in/joakimmalmstrom/" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
            <div className="col-3 col-lg-1">
              <a href="mailto: joakim.s.malmstrom@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </div>
        </div>
        {/* Competences */}
        <div className="col-12 col-lg-4">
          <h5>Competences</h5>
          <h6>Game Engines</h6>
          <p>{parseData(gameEngines)}</p>
          <h6>Languages</h6>
          <p>{parseData(languages)}</p>
          <h6>Frameworks</h6>
          <p>{parseData(frameworks)}</p>
          <h6>Cloud Services</h6>
          <p>{parseData(cloud)}</p>
        </div>
      </div>
    </div>
  )
}

function parseData(data: string[]): string {
  return data.join(', ');
}

export default About