import React from 'react'
import Title from "../../components/title/Title"
import ImageService from "../../services/ImageService.service"
import { Link } from "react-router-dom";

const AscendersBlog1: React.FC = () => {
  const jumpingPoints = [
    'To the right is a box with the jump button visually represented',
    'In front of the player is a text with the word "JUMP"',
    'I wanted to make it SUPER clear so that the player would not learn this. I even made the player repeat it after the first obstacle',
  ];
  const buildingPoints = [
    'Double communicated; text and control visually represented on a box',
    'Requires only one pair of blocks to reach the next platform',
    'Forced the player to build instead of trying to jump to the next platform',
  ];
  const shootingPoints = [
    'Jumping',
    'Building',
    'Shooting',
  ];
  const knockbackPoints = [
    'A bullseye on the wall so that the player would want to hit the center',
    `A far drop down to the ground for that 'this is sparta' moment`,
    'The knockback has small range and here the player could try out just how close they need to be to execute it',
  ];
  const elevatorPoints = [
    'The elevator has a pulsating effect for easy recognition',
    'Activates when the player stands on it and resets when they are not',
  ];
  const finalePoints = [
    'An arrow pointing to the Beacon with an extra text',
    'A small platform in the middle of the room so the player would not miss it and then sighting the next',
    `Strong particle effects around the Beacon to really emphazise it's location and value`,
    'The load up with the ready screen',
  ];

  return (
    <div className="row">
      <Title name="Behind the Development: Ascenders (1 / 2)"></Title>
      
      <div className="col-12">
        <span className="badge bg-secondary mb-2">21 March 2020</span>

        <p>
          This is a two-part series where I'm going to explain the decisions behind the design of Ascenders. In this first part I'm going to be talking about the tutorial's level design.
          You can see part two here: <Link to="/blog/ascenders-2">Behind the Development: Ascenders (2 / 2)</Link>
        </p>

        {/* Jumping */}
        <div className="col-12">
          <h5>Jumping</h5>
          <p>
            This is the first the player sees when starting the game. I wanted the player to start learning the first essential feature right away; jumping.
          </p>
          <div className="col-12 col-lg-6">
            <img src={ImageService.getImage('ascenders/ascenders-jump', true)} alt="" className="img img-fluid" />
            <ul>
              {jumpingPoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Building */}
        <div className="col-12">
          <h5>Building</h5>
          <p>
            I wanted to limit the player's options where they could build. So that there were no misconceptions where the player would go.
          </p>
          <div className="col-12 col-lg-6">
            <img src={ImageService.getImage('ascenders/ascenders-build', true)} alt="" className="img img-fluid" />
            <ul>
              {buildingPoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Shooting */}
        <div className="col-12">
          <h5>Shooting</h5>
          <p>
            The yellow cubes would teach the player two things: How to shoot and that cubes are destructable.
          </p>
          <div className="col-12 col-lg-6">
            <img src={ImageService.getImage('ascenders/ascenders-shoot', true)} alt="" className="img img-fluid" />
            <p>
              For this section only, I made the player repeat all the new features they've learned in order to progress through the level:
            </p>
            <ul>
              {shootingPoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Knockback */}
        <div className="col-12">
          <h5>Knockback</h5>
          <p>
            When the player reached this section of the level they need to learn their final mechanic; Knockback. The character in front is a copy of the opponent.
          </p>
          <div className="col-12 col-lg-6">
            <img src={ImageService.getImage('ascenders/ascenders-knockback', true)} alt="" className="img img-fluid" />
            <ul>
              {knockbackPoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Elevator */}
        <div className="col-12">
          <h5>Elevator</h5>
          <p>
            The next level has some elevators and we want to introduce the player early on so that they would not be confused.
          </p>
          <div className="col-12 col-lg-6">
            <img src={ImageService.getImage('ascenders/ascenders-elevator', true)} alt="" className="img img-fluid" />
            <ul>
              {elevatorPoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Finale */}
        <div className="col-12">
          <h5>Finale</h5>
          <p>
            A longer sequence where the player must build to the top to reach the Beacon which is the winning state. The first player to reach and loads up the Beacon wins.
          </p>
          <div className="col-12 col-lg-6">
            <img src={ImageService.getImage('ascenders/ascenders-finale', true)} alt="" className="img img-fluid" />
            <ul>
              {finalePoints.map(point => (
                <li>{point}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Summary */}
        <div className="col-12">
          <h5>Summary</h5>

          <p>
            I'm not a huge fan of tutorial levels. I said it, but since we had a limited amount of time on a show floor (Gotland Game Conference), we decided we needed to learn the players in a safe environment before they faced each other.
            My sole princible for doing the level design of the tutorial was that it needed to be short and tight in its learning.
          </p>
          <p>
            Next week I'll talk about the actual level where the players face each other. That'll be a fun one!
          </p>

          <h5>- Joakim Malmström</h5>
        </div>

      </div>
    </div>
  )
}

export default AscendersBlog1