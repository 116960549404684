import React from 'react'
import Title from "../../../components/title/Title"
import ImageService from "../../../services/ImageService.service"
import { Link } from "react-router-dom";

const HomewardBound: React.FC = ({ }) => {
  return (
    <div>
      <Title name="Homeward Bound" isGameTitle={true}></Title>

      {/* About */}
      <div className="row center-content">
        <div className="col-8">
          <p>
            Homeward Bound is a co-op puzzle platformer where two players are bound together with a rope.
            Use the rope in various ways to interact with each other and objects in the environment to complete the challenges ahead!
          </p>
          <p>
            The key mechanic of the game centers around the rope and how the two players will solve the puzzles with it.
          </p>
          <p>
            Homeward Bound is the result of a two month long project, made by game design students.
            The goal was to develop a game for one of the team member's nephew as a gift. We put our heads together and managed to create this game!
          </p>
        </div>
      </div>

      {/* Trailer */}
      <div className="row">
        <div className="col-12">
          <iframe width="100%" height="630px" src="https://www.youtube.com/embed/fv_h7UUyIO8?si=Dy68QEjeGMnLRJpE" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
      </div>

      {/* Developers / Blog */}
      <div className="row center-content mb-5">
        <div className="col-12 col-lg-5">
          <h5>Developers</h5>
          <p>Joakim Malmström - Game Programmer & Product Owner</p>
          <p>Krzesimir Pszenny - Scrum Master</p>
          <p>Matti Johansson - Game Designer & Sound</p>
          <p>Lilja Bräckman - Graphical Artist</p>
        </div>
        <div className="col-12 col-lg-3">
          <h5>Behind the Development</h5>
          <Link to="/blog/homeward-bound">
            <img src={ImageService.getImage('homeward-bound/ba-thumbnail')} className="image img-fluid" alt="" />
          </Link>
        </div>
      </div>

      {/* Game Link */}
      <div className="row center-content">
        <div className="col-6 col-lg-2">
          <a href="https://mattidev.itch.io/homeward-bound" target="_blank">
            <img src={ImageService.getItchIoImage()} className="image img-fluid" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomewardBound
