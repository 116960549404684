import React from 'react'
import Title from "../../../components/title/Title"
import ImageService from "../../../services/ImageService.service"
import { Link } from "react-router-dom";

const Behemoth: React.FC = ({ }) => {
  return (
    <div>
      <Title name="Behemoth" isGameTitle={true}></Title>

      {/* About */}
      <div className="row center-content">
        <div className="col-8">
          <p>
            Behemoth is a 2D side-scrolling Shoot 'em Up. 
            Operate the infamous Behemoth ship with it's cannon, defensive shields and special super ray! Face various enemy ships with their own characteristic movement and attacking abilities. 
          </p>
          <p>
            The world is at war. You have succesfully infiltrated the enemy lines and stolen a massive prototype ship, you now have to fly it back to your home base!
          </p>
        </div>
      </div>

      {/* Game Images */}
      <div className="row mb-3">
        <div className="col-12 col-lg-4 mb-3">
          <img src={ImageService.getImage('behemoth/behemoth-gameplay-1')} className="image-inspect img-fluid" alt="behemoth-game-img-1" />
        </div>
        <div className="col-12 col-lg-4 mb-3">
          <img src={ImageService.getImage('behemoth/behemoth-gameplay-3', true)} className="image-inspect img-fluid" alt="behemoth-game-img-1" />
        </div>
        <div className="col-12 col-lg-4 mb-3">
          <img src={ImageService.getImage('behemoth/behemoth-gameplay-2')} className="image-inspect img-fluid" alt="behemoth-game-img-2" />
        </div>
      </div>

      {/* Developers / Blog */}
      <div className="row center-content mb-5">
        <div className="col-12 col-lg-5">
          <h5>Developers</h5>
          <p>Joakim Malmström - Level Design & Product Owner</p>
          <p>Jad El Masri - Game Programmer</p>
          <p>Isac Thorsson - Game Programmer</p>
          <p>Niklas Eriksson - Project Manager</p>
          <p>Jesper Karbing - Graphical Artist</p>
          <p>Roberto Marco Söderström - Graphical Artist</p>
        </div>
        <div className="col-12 col-lg-3">
          <h5>Behind the Development</h5>
          <Link to="/blog/behemoth">
            <img src={ImageService.getImage('behemoth/behemoth-start')} className="image img-fluid" alt="" />
          </Link>
        </div>
      </div>

      {/* Game Link */}
      <div className="row center-content">
        <div className="col-6 col-lg-2">
          <a href="https://rob-ms.itch.io/behemoth" target="_blank">
            <img src={ImageService.getItchIoImage()} className="image img-fluid" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Behemoth;