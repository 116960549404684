import React from 'react'
import Title from "../../components/title/Title"
import './Blog.css'
import ImageService from "../../services/ImageService.service"
import { Link } from "react-router-dom"

interface BlogThumbnail {
  header: string,
  text: string,
  img: string,
  path: string,
  date: { day: number, month: string, year: number },
};

const Blog: React.FC = () => {
  const blogs: BlogThumbnail[] = [
    {
      header: 'Behind the Development: Behemoth',
      text: 'Behemoth is a 2D side-scrolling Shoot em Up where you control this big ship with firing cannons and protective shields to destroy enemy ships.',
      img: ImageService.getImage('behemoth/behemoth-start'),
      path: '/blog/behemoth',
      date: { day: 8, month: 'March', year: 2020, }
    },
    {
      header: 'Behind the Development: Paint Your Way',
      text: 'Paint Your Way is a four player, competitive, 2D isometric, goofy, racing game with a special designed paint roller as the controller.',
      img: ImageService.getPortfolioImage('paint-your-way'),
      path: '/blog/paint-your-way',
      date: { day: 14, month: 'March', year: 2020, }
    },
    {
      header: 'Behind the Development: Ascenders\n(1 / 2)',
      text: 'This is a two-part series where I am going to explain the decisions behind the design of Ascenders.',
      img: ImageService.getImage('ascenders/ascenders-thumbnail-1'),
      path: '/blog/ascenders-1',
      date: { day: 21, month: 'March', year: 2020, }
    },
    {
      header: 'Behind the Development: Ascenders\n(2 / 2)',
      text: 'This is the second and final part of Behind the Design: Ascenders. This post is about the actual level of the game.',
      img: ImageService.getImage('ascenders/ascenders-thumbnail-2'),
      path: '/blog/ascenders-2',
      date: { day: 28, month: 'March', year: 2020, }
    },
    {
      header: 'Homeward Bound released!',
      text: 'Me and a small team, developed this outside of our studies over a period of two months. I would like to really thank me incredible team for making this small dream of mine come true!',
      img: ImageService.getImage('homeward-bound/ba-thumbnail'),
      path: '/blog/homeward-bound',
      date: { day: 22, month: 'June', year: 2020, }
    },
    {
      header: 'Behind the Development: LMS',
      text: 'This fall I started a .NET Developer course at Lexicon. Recently we had a project for a LMS (Learning Management System).',
      img: ImageService.getImage('lms/full-course'),
      path: '/blog/lms',
      date: { day: 13, month: 'December', year: 2020, }
    },
    {
      header: 'Project Wolf Pack - Part 1',
      text: 'You are a pack of wolves running through the woods and do what needs to be done in order to survive.',
      img: ImageService.getImage('wolf-pack/wolf-pack-thumbnail-1'),
      path: '/blog/wolf-pack-1',
      date: { day: 13, month: 'December', year: 2020, }
    },
  ]

  return (
    <div className="row">
      <Title name="Blog"></Title>

      {blogs.reverse().map(blog => (
        <div className="col-12 col-lg-4 blog-item">
          <Link to={blog.path} className="blog-link">
            <h5>{blog.header}</h5>
            <p>{blog.date.day} {blog.date.month} {blog.date.year}</p>
            <img src={blog.img} alt="" className="img img-fluid" />
            <p>{blog.text}</p>
          </Link>
        </div>
      ))
      }
    </div >
  )
}

export default Blog