import React from 'react'
import Title from "../../../components/title/Title"
import ImageService from "../../../services/ImageService.service"
import { Link } from "react-router-dom";

const Ascenders: React.FC = ({ }) => {
  return (
    <div>
      <Title name="Ascenders" isGameTitle={true}></Title>

      {/* About */}
      <div className="row center-content">
        <div className="col-8">
          <p>
            Adapt to simplistic, but dynamically changed environment and be the first one to reach the Beacon at the top of the tower!
          </p>
          <ul>
            <li>Generate glass cubes and use them to climb up swiftly</li>
            <li>Shoot blasts to smash opponent's structures</li>
            <li>Take advantage of the shock waves</li>
            <li>Engage in melee range and knock-back your opponent around</li>
          </ul>
          <p>
            Ascenders combines and extracts the best from shooter, platformer and race genres. It’s a swift 1v1 race in an enclosed tower with simplistic, but dynamically changed environment.
          </p>
        </div>
      </div>

      {/* Trailer */}
      <div className="row">
        <div className="col-12">
          <iframe width="100%" height="630px" src="https://www.youtube.com/embed/Wa7jiyX5S0o?si=eIeOsi59BD3frUSB" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
      </div>

      {/* Developers / Blog */}
      <div className="row center-content mb-5">
        <div className="col-12 col-lg-5">
          <h5>Developers</h5>
          <p>Joakim Malmström - Designer & Product Owner</p>
          <p>Krzesimir Pszenny - Scrum Master</p>
          <p>Matti Johansson - Game Programmer & Sound</p>
          <p>Marie Collin - Graphical Artist</p>
        </div>
        <div className="col-12 col-lg-3">
          <h5>Behind the Development</h5>
          <Link to="/blog/ascenders-1">
            <img src={ImageService.getImage('ascenders/ascenders-thumbnail-1')} className="image img-fluid" alt="" />
          </Link>
        </div>
      </div>

      {/* Game Link */}
      <div className="row center-content">
        <div className="col-6 col-lg-2">
          <a href="https://offlit.itch.io/ascenders" target="_blank">
            <img src={ImageService.getItchIoImage()} className="image img-fluid" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Ascenders;