import React from 'react'
import Title from "../../components/title/Title"
import ImageService from "../../services/ImageService.service"

const LmsBlog: React.FC = () => {
  return (
    <div className="row">
      <Title name="Behind the Development: LMS"></Title>

      <div className="col-12">
        <span className="badge bg-secondary mb-2">13 December 2020</span>

        <p>
          This fall I started a .NET Developer course at Lexicon. Recently we had a project for a LMS (Learning Management System) which we developed with MVC.
          I thought it would be a good idea to display some of my work from it.
        </p>
        <p>
          Take part of the code here: <a href="https://github.com/Joakimmalmstrom/LexiconLMS" target="_blank">Github</a>
        </p>
      </div>

      {/* Full teacher view & Course info */}
      <div className="row mb-4">
        <div className="col-12 col-lg-6">
          <h5>Full teacher view</h5>
          <img src={ImageService.getImage('lms/full-course')} alt="" className="img img-fluid" />
        </div>
        <div className="col-12 col-lg-6">
          <h5>Course info</h5>
          <img src={ImageService.getImage('lms/course-info')} alt="" className="img img-fluid" />
        </div>
      </div>

      {/* Current module & Assignments */}
      <div className="row mb-4">
        <div className="col-12 col-lg-6">
          <h5>Current module</h5>
          <img src={ImageService.getImage('lms/current-module')} alt="" className="img img-fluid" />
        </div>
        <div className="col-12 col-lg-6">
          <h5>Assignments</h5>
          <img src={ImageService.getImage('lms/assignments')} alt="" className="img img-fluid" />
        </div>
      </div>

      {/* Uploaded assignments */}
      <div className="row mb-4">
        <div className="col-12 col-lg-6">
          <h5>Uploaded assignments</h5>
          <img src={ImageService.getImage('lms/uploaded')} alt="" className="img img-fluid" />
        </div>
      </div>

      <div className="col-12">
        <p>
          I hope this brought you some clarity in how the functionality works. This is a very small part of the project and I might post more in the coming future.
        </p>
        <p>
          Until then, stay safe!
        </p>
        <h5>- Joakim Malmström</h5>
      </div>
    </div>
  )
}

export default LmsBlog