import React from 'react'
import Portfolio from "../../components/portfolio/Portfolio"
import About from "../../components/about/About"

const Home: React.FC = () => {
  return (
    <div>
      <Portfolio />
      <About />
    </div>
  )
}

export default Home